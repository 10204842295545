// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { defaultLocale } from "BrandData";

export function getDomain() : string|null {
  const dp = document.location.hostname.split('.')
  let domain = null;
  if(dp.length >= 2) {
      domain = '.' + dp[dp.length-2] + '.' + dp[dp.length-1];
  }
  return domain;
}

export function setCC2LocaleCookie(locale: string) {
  const domain = getDomain();
  if(domain) {
    // domain and path must be set. ".domain": the dot is mandatory to share between subdomains.
    document.cookie = `cc2locale=${locale};domain=${domain};path=/;`
  }
}

export const fixShortLocale = (locale: string) => {
  if (locale.length < 3) {
    switch(locale) {
      case 'en':
        return defaultLocale;
      default:
        return `${locale}-${locale.toUpperCase()}`        
    }
  }
  return locale;
}

export function formatBytes(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}

// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { messages } from "BrandData";
export async function setLocale(i18n, host: string, newLocale: string) {
  // always load the fallback locale
  // otherwise different languages cannot fallback and frontend shows keypath instead
  await ensureTextLoaded(i18n, host, i18n.fallbackLocale);
  
  const locale = fixShortLocale(newLocale);
  await ensureTextLoaded(i18n, host, locale);
  i18n.locale = locale;
}

async function ensureTextLoaded(i18n, host: string, locale: string) {
  if (!i18n.messages[locale]) {
    const texts = await messages[locale](host);
    i18n.setLocaleMessage(locale, texts);
  }
}

////// @ref https://github.com/kazupon/vue-i18n/issues/620#issuecomment-1660638535

// `choiceOptions` relates to the number of options for the specified translation
// `i18n.tc()` accepts 2 or 3 options, so the code is covering both cases
// In case of translation having only 2 options, empty and single options will be the same
// `choiceOptions` value is the length of options for that translation
const getOptionsByTranslationChoices = (choiceOptions) => 
  choiceOptions === 3 
  ? {
    returnAsEmpty: 0,
    returnAsSingle: 1,
    returnAsPlural: 2,
  } 
  : {
    returnAsEmpty: 0,
    returnAsSingle: 0,
    returnAsPlural: 1,
  };

/**
 * A helper function for applying decimals as plural for english speakers
 * @param {number} [choice] - Number passed to `i18n.tc()` function as value to be resolved
 * @param {number} [choiceOptions] - `3` or `2`: Number of options available on the translation file to that specific key
 * @returns {number} 0: `empty`, 1: `single`, 2: `plural`
 */
const applyingDecimalsAsPlural = (choice, choiceOptions) => {
  // These codes are related to `vue-i18n` internals to pluralize resolution
  //  - 0: it should return empty value as result
  //  - 1: it should return single value as result
  //  - 2: it should return plural value as result
  const { returnAsEmpty, returnAsSingle, returnAsPlural } = getOptionsByTranslationChoices(choiceOptions);

  // Returns empty if receives `0` as value
  if (choice === 0) {
    return returnAsEmpty;
  }

  // NOTE: In english, decimals are also plural
  // This function always receives value as `number`, so it's safe to assume
  // decimal values as result of `!Number.isInteger()` and return as plural
  if (!Number.isInteger(choice)) {
    return returnAsPlural;
  }

  // Otherwise, check if number is more than 1 to return as plural or single
  return choice > 1 ? returnAsPlural : returnAsSingle;
};

export const pluralizationRules = {
  'en': applyingDecimalsAsPlural, 'en-US': applyingDecimalsAsPlural, 
  'it': applyingDecimalsAsPlural, 'it-IT': applyingDecimalsAsPlural,
  'de': applyingDecimalsAsPlural, 'de-DE': applyingDecimalsAsPlural,
  'es': applyingDecimalsAsPlural, 'es-ES': applyingDecimalsAsPlural,
  'fr': applyingDecimalsAsPlural, 'fr-FR': applyingDecimalsAsPlural,
};
