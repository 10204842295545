import { AppFeatures, initAppFeatures } from './utils/Features'
import KeyboardShortcutsMgr from './utils/shortcuts/KeyboardShortcuts';
initAppFeatures()

import Vue from 'vue';
import VueI18n, { DateTimeFormats } from 'vue-i18n'
import VueRouter, { RouteConfig } from 'vue-router';
import routes, { RouteConfigAuth } from './routes/routes';
import { routeMap } from './routes/routes';
import { setGlobalRouter } from './routes/router';

import keycloakLogout from './components/vuejs-keycloak/security/logout'
import vuejsKeycloakSecurity from './components/vuejs-keycloak/security'
import vuejsKeycloak from './components/vuejs-keycloak'

import PermissionEntity from './constant/PermissionEntity';
import AbilityProvider from './classes/utils/implementation/AbilityProvider';
import PermissionAction from './constant/PermissionAction';
import { 
  _omit,
} from './lodashboot';

import store from './store/store';
import { StoreLoader } from "./store/store-loader";
import { setStore } from './classes/GlobalStore';
import CommunicationSettings from './communication/CommunicationSettings';

import {pluralizationRules, setCC2LocaleCookie, setLocale} from "@/utils/i18n";
// Vuetify locales
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import es from 'vuetify/lib/locale/es'
import fr from 'vuetify/lib/locale/fr'
import it from 'vuetify/lib/locale/it'

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import { constants } from '../constants';

import { loadStatusPage } from '@/utils/statuspage/loaderStatusPage';

// exclude local instances
if (!location.href.match( /(http:\/\/.*)|(corvina\.fog.*)/ ) ) {
  datadogRum.init({
    applicationId: '47c126f5-eebf-484f-93e1-903372c64657',
    clientToken: 'pubdf65df43e838a0a8b1a18a814c539c02',
    site: 'datadoghq.eu',
    service: constants.APP_NAME,
    
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: constants.APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    defaultPrivacyLevel:'mask-user-input',

    excludedActivityUrls: [ /http:\/\/.*/, /corvina\.fog.*/, /corvina\.mk.*/ ] 
  });

  
  datadogLogs.init({
    clientToken: 'pubdf65df43e838a0a8b1a18a814c539c02',
    site: 'datadoghq.eu',
    service: constants.APP_NAME,
    
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: constants.APP_VERSION,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100
  });
  //datadogRum.startSessionReplayRecording();
}
// TODO: check permissions. Consider switching to onAll: see hasPermissionToRoute()
type RoutePermissionMapper = [PermissionAction[], PermissionEntity[]]

interface RouterPermissionMapperClass {
  iotOnly?: boolean,
  vpnOnly?: boolean,
  permissions: RoutePermissionMapper[]
}

type NextArgs = any;

Vue.use(VueRouter);
let router = new VueRouter({
  routes,
  linkActiveClass: 'active',
});

let localeFromLogin = null;
try{
  const cookieLocale = document.cookie.split('; ').find(row => row.startsWith('cc2locale='));
  if(cookieLocale) {
    localeFromLogin = cookieLocale.split('=')[1];
  }
} catch(e) {
}

// @ts-ignore : ignore BrandData import since it needs to be resolved at runtime
import { defaultLocale, defaultStandardTime, statusPage, statusPageId  } from "BrandData";

Vue.use(VueI18n)
export const dateTimeFormats : DateTimeFormats = {
  'en-US': defaultStandardTime,
  'de-DE': defaultStandardTime,
  'es-ES': defaultStandardTime,
  'fr-FR': defaultStandardTime,
  'it-IT': defaultStandardTime
} as any

/* export current configuration for other modules outside vue */ 
const locale = localeFromLogin || defaultLocale;
export const i18n : VueI18n = new VueI18n({
  dateTimeFormats,
  messages: {},
  locale: locale,
  fallbackLocale: defaultLocale,
  pluralizationRules
});

let iotEnabled;
let vpnEnabled;

function hasPermissionsInToken() {
  return CommunicationSettings.$keycloak?.tokenParsed?.authorization?.permissions?.length > 0;
}

export async function initCorvina( setupCorvinaInstance )
{
  await StoreLoader.loadPermissionStores();
  setupCorvinaInstance();
  await setLocale(i18n, CommunicationSettings.i18nHost, locale);
  const authenticated = router.resolve(document.location.hash.slice(1), undefined, false).route.meta.requiresAuth === false || await isAthenticated();

  if (authenticated) {
    loadFontUtil();

    datadogRum.setUser({
      name: store.getters["permission/getLoggedUser"]?.toString(),
    })

    if (hasPermissionsInToken()) {
      await StoreLoader.loadBaseUIStores();
      loadStatusPage(statusPage, statusPageId);
    }
  }
  init();
}

let __logging_out__ = false;``

export function logout()
{
  // clear current organization cached in url
  const query = { ... router.currentRoute?.query };
  delete query.org
  __logging_out__ = true;
  router.replace({ query }, () => { 
    // This timeout is required to avoid request cancellation after the query changes causing some gfx to redraw
    setTimeout(() => { keycloakLogout(); }, 0);
  });
}

function init()
{
  CommunicationSettings.realm().then(async () =>
  {
    // Add methods to Storage
    const CircularJSON = require('circular-json');
    Storage.prototype.setObject = function (key, value) {
      this.setItem(key, CircularJSON.stringify(value));
    }

    Storage.prototype.getObject = function (key) {
      var value = this.getItem(key);
      return value && CircularJSON.parse(value);
    }

    // Set Global store
    setStore(store);

    // Setup router
    // Vue.use(VueRouter);
    // let router = new VueRouter({
    //   routes,
    //   linkActiveClass: 'active',
    // });
    // Authentication is checked before each navigation
    router.beforeEach( async (to, from, next) => {
      store.dispatch('frame/showLoader');
      const nextRouteAuthChecked = checkAuthentication( to, from, next, router );
      let nextRoute
      if (!__logging_out__) {
        // avoid reassigning the org query params during logout
        const nextRouteOrgChecked = to.meta.requiresAuth === false ? undefined : await checkOrganization( to, from );
        nextRoute = nextRouteOrgChecked || nextRouteAuthChecked;
      } else {
        nextRoute = nextRouteAuthChecked;
      }
      if ( nextRoute && nextRoute.name == from.name )
        store.dispatch('frame/hideLoader');
      next(nextRoute);  
    });
    router.afterEach(() => {
      store.dispatch('frame/hideLoader');
      removeGlobalLoader();
    })
    setGlobalRouter(router);

    // Mount App
    console.log( "Loading UI" )
    const AppModule = await import( './App.vue' );
    const VuetifyModule = await import( 'vuetify' );
    const App = AppModule.default;
    const Vuetify = VuetifyModule.default;
    Vue.use(Vuetify);
    const vuetify = new Vuetify({
      icons: {
        iconfont: 'md',
      },
      breakpoint: {
        mobileBreakpoint: 'sm'
      },
      lang: {
        locales: {
          de, "de-DE": de,
          en, "en-US": en,
          es, "es-ES": es,
          fr, "fr-FR": fr,
          it, "it-IT": it
        },
        current: i18n.locale,
      }
    });
    const vue = new Vue({
      i18n,
      vuetify,
      router,
      store,
      el: '#app',
      render: h => h(App)
    });
    KeyboardShortcutsMgr.getInstance();
  }).catch( (err) => {
    let pos = location.hostname.indexOf("app");
    alert(`Cannot navigate to organization '${location.hostname.slice(0,pos-1)}'.`)
    location.hostname = location.hostname.slice(pos);
  })
}

function checkAuthentication( to, from, next, router ): NextArgs
{
  // this is required otherwise it loops forever
  if (from.meta.mainError === 'TRIGGERED') {
    console.warn("main.ts beforeEach error TRIGGERED")
    return undefined;
  }
  try {
    // clear any progress
    if (to.meta.requiresAuth === false) {
      return undefined;
    } else {
      const auth = (vuejsKeycloak.state as any).security.auth
      if (!auth.authenticated) {
        vuejsKeycloakSecurity.init(next /*, to.meta.roles*/).then(function (value) {
          if (router.mode == 'hash') {
            // OLD:
            // returned url is /#/devices&state=...&session...
            // need to make it compotible with vue router which does not expect the first &
            // NEW: removed check since it was messing with query parameters
            let hashPos = window.location.href.indexOf('#');
            let hashString = window.location.href.slice(hashPos + 1)
            return { path: hashString };
          } else {
            return undefined;
          }
        }).catch(function (error) {
          // to.name !== 'error' ? next({ name: 'error' }) : next(); 
          console.log("Redirect to organization selection after security error: ", error)
          to.name !== 'error' ? next({ name: 'organization', query: { redirectTo: to.path, ...to.query } }) : next();
        });
      }
      else {
        if (to.meta.roles) {
          if (vuejsKeycloakSecurity.roles(to.meta.roles[0])) {
            return undefined;
          }
          else {
            // to.name !== 'unauthorized' ? next({ name: 'unauthorized' }) : next();
            console.log("Redirect to organization selection after missing roles ")
            to.name !== 'unauthorized' ? next({ name: 'organization', query: { redirectTo: to.path, ...to.query } }) : next();
          }
        }
        else {
          return undefined;
        }
      }
    }
  } catch (error) {
    console.error("main error: ", error);
    // set break loop condition
    from.meta.mainError = 'TRIGGERED'
    console.log("Redirect to organization selection after error ", error)
    return { name: 'organization', query: { redirectTo: to.path, ...to.query } };
  }
}

export function hasPermissionToRouteName(route: string): boolean {
  return hasPermissionToRoute(routeMap.get(route));
}

export function hasPermissionToRoute(route: RouteConfig): boolean {
  const abilityHelper = AbilityProvider.getRootCompanyAbilityHelper();
  const rpms = route?.meta?.auth as RouteConfigAuth;
  if(rpms === undefined) {
    // no required permission for this route
    return true
  }
  if (rpms.iotOnly && !iotEnabled || rpms.vpnOnly && !vpnEnabled) {
    return false;
  }

  return rpms.permissions.reduce<boolean>( (value: boolean|undefined, rpm ) => {
    // TODO: onOne or onAll ?
    // OR between permissions on this entity
    if (!iotEnabled) {
      // skip any required permission not required if iot is not enabled
      // (for instance walkthrough requires model access only in iot context)
      for( let p of rpm[1]) {
        if ( p == PermissionEntity.MODELS || 
          p == PermissionEntity.DASHBOARDS || 
          p == PermissionEntity.NOTIFICATIONS || 
          p == PermissionEntity.ALARMS ) {
            return value;
          }
      }
    }
    const current: boolean = rpm[0].reduce<boolean>( (value, permission) => {
      return value || abilityHelper.hasPermissionOnOneOfEntities(permission,rpm[1])
    }, false);
    // AND between permissions-entities tuples
    return value == undefined ? current : (value && current)
  }, undefined)
          
}

async function checkOrganization( to, from ): Promise<NextArgs>
{
  // this is required otherwise it loops forever in case of
    // 1. user belongs to no organizations
    // 2. user belongs to more than one organization
    const abilityHelper = AbilityProvider.getRootCompanyAbilityHelper();
  
    // Before anything else, ensures current user organizations are fetched (required by organizationcomponent)
    let organizations = store.getters['permission/getOrganizations'];
    //const bFetchTheme = !organizations.selectedOrganization
    if(organizations.currentUserOrg.length === 0) {
      try {
        await store.dispatch('permission/fetchCurrentUserOrganizations');
      } catch(er) {
        organizations = []
      }
    }

    if(to.name === 'organization' ) {
      if ( !to.query.org /* if orgs are given in query , try them before showing the selection window */ ) {
        return;
      }
    }
    if(organizations.selectedOrganization && to.name === 'agreements' ) {
      return;
    }

    if (to.path == "/vpn_logout") {
      console.log("Closing vpn tab ...");
      window.close();
      return { path: "/", query: { ... to.query } } ;
    }

    try {
      let selectSubOrgRequired = false;
      store.dispatch('permission/setLoginError', null)
      if( !organizations.selectedOrganization || ( to.query.org && organizations.selectedOrganization.id != to.query.org[0]) ) {
        selectSubOrgRequired = true;
        if (to.query.org) {
          const orgToSelect = organizations.currentUserOrg.find( o => o.id == to.query.org[0] )
          if(orgToSelect) {
            await store.dispatch('permission/selectOrganization', orgToSelect);
          } else {
            console.log(`Navigate to organization selector because current selected org ${to.query.org[0]}  cannot be found`)
            return { name: 'organization', query: { redirectTo: to.path, ... _omit(to.query, ['org']) } };
            
          }
        } else {
          if(organizations.currentUserOrg.length === 1) {
            await store.dispatch('permission/selectOrganization', organizations.currentUserOrg[0]);
          } else {
            console.log("Navigate to organization selector because #org != 1")
            return { name: 'organization', query: { redirectTo: to.path, ... _omit(to.query, ['org']) } };
          }
        }
      } 
      // }
      organizations = store.getters['permission/getOrganizations'];
      const selectedOrganization = organizations.selectedOrganization;
      

      if(selectedOrganization && selectedOrganization.resourceId) {
        // must fetch the token for the selected org
        if (!sessionStorage.getItem('scope')) {
          // immediately get a token for the selected organization
          const scope = `org:${selectedOrganization.resourceId} app:*`;
          sessionStorage.setItem('scope', scope);
          
          await CommunicationSettings.keycloak({ 
            onLoad: 'login-required', 
            responseMode: 'fragment', 
            checkLoginIframe: false, 
            scope 
          });
        }

        // Cannot access org -> show error
        if(!hasPermissionsInToken()) {
          console.warn("No permissions in token to enter organization ", selectedOrganization.resourceId)
          const message = "No permissions in token to enter organization " + selectedOrganization.resourceId
          store.dispatch('permission/setLoginError', message);
          return { name: 'organization', query: { redirectTo: to.fullPath } };
        }
      }

      store.commit("permission/SET_HAS_RPT_TOKEN", true)

      // at this point, with a valid token for the main org, if a suborg is given, we can try to select it
      //  || (to.query.org[1] && organizations.selectedSubOrganization.id != to.query.org[1])
      if (selectSubOrgRequired) {
        if (to.query.org && to.query.org[1]) {
          const subOrgToSelect = await store.dispatch('permission/fetchOrganization', to.query.org[1]);//{ page: 0, id: to.query.org[1], pageSize: 500, includePrivateAccess: false, onlyFirstLevel: false, organizationId: selectedOrganization.id });
          //const subOrgToSelect = orgs.find( o => o.id == to.query.org[1] )
          if(subOrgToSelect) {
            await store.dispatch('permission/selectCurrentOrganization', subOrgToSelect);
          } else {
            await store.dispatch('permission/selectCurrentOrganization', organizations.selectedOrganization);
          }
        } else {
          await store.dispatch('permission/selectCurrentOrganization', organizations.selectedOrganization);
        }

        await store.dispatch('theme/loadTheme')
      }

      // set current org in query
      to.query.org = store.getters['permission/getRouteQueryOrg']

      // cannot do this in routes.js as we do not have a selected organization yet
      iotEnabled = store.getters['permission/getCurrentOrganizationIOTEnabled'];
      vpnEnabled = store.getters['permission/getCurrentOrganizationVPNEnabled'];

      // check agreements
      if(!store.getters['permission/getAgreementsReminderShownInSession']){
        const agreementsToCheck = await store.dispatch('permission/checkAgreements', selectedOrganization.id);
        console.log("AppLayout agreements", agreementsToCheck)
        if(agreementsToCheck && agreementsToCheck.types && agreementsToCheck.types.length != 0) {
          return { name: 'agreements', query: { org: to.query.org, redirectTo: to.fullPath } };
        } else {
          // already checked for this session: no other checks needed
          store.dispatch('permission/setAgreementsReminderShownInSession', true);
        }
      } else {
        console.log("AppLayout agreements reminder already shown: skip")
      }

      // fetch user preferences locale
      // vuetify language is assigned in appLayout.vue in created()
      if(!store.getters["theme/fetchedLocale"]) {
        let fetchUserPreferences= await store.dispatch('permission/getUserPreferences', {
          serviceName: "locale",
          username: store.getters["permission/getLoggedUser"].toString(),
        }) ;
        let language = fetchUserPreferences.content.find(el=> el.serviceName==="locale")
        if(language && language.value1){
          // if found the preferences change the default language for this user
          await setLocale(i18n, CommunicationSettings.i18nHost, language.value1);
        } else {
          // first time: save current locale preference
          let updatedParameters = {
            serviceName: "locale",
            username: store.getters["permission/getLoggedUser"].toString(),
            value1:  i18n.locale
          }
          await store.dispatch('permission/postUserPreferences', updatedParameters) ;
        }
        store.dispatch('theme/setFetchedLocale', true);
        // update cookie for Keycloak sync
        setCC2LocaleCookie(i18n.locale);
      }

      // TODO: fetch user's home page
      if(to.name == 'home') {
        return { name: getHomeRoute({iotEnabled, vpnEnabled}), query: { org: to.query.org } };
      }

      if(!hasPermissionToRoute(to)) {
        console.log(`Error getting permissions for ${to.name} => navigate to home`)
        // TODO: what if user has no permission to go to the home page?
        return { name: 'home', query: { org: to.query.org } };
        
      }

      // skip the organization selection page if selection is available
      if (to.name == 'organization') {
        return { name: 'home' , query: { org: to.query.org }};
      } else {
        return;
      }
    } catch(error) {
      const message = "Reload organization selector after error " + (error.message || error)
      store.dispatch('permission/setLoginError', message)
      // console.error("AppLayout catch", error)
      console.error("Reload organization selector after error ", error)
      // reset current selection to avoid triggering visualition of topbar/sidebar, which will fail because suborg is missing
      await store.dispatch('permission/selectOrganization', undefined);
      // reset scope (maybe is wrong)
      sessionStorage.removeItem('scope');
      return { name: 'organization', query: { redirectTo: to.fullPath } };
    }
}

function getHomeRoute({iotEnabled, vpnEnabled}: {iotEnabled: boolean, vpnEnabled: boolean}): string {
  // Organization has only VPN enabled home route is vpn-devices
  if (!iotEnabled && vpnEnabled && hasPermissionToRoute(routeMap.get("vpn-devices"))) {
    return "vpn-devices";
  }

  // IOT is enabled so select first route with permission
  if (hasPermissionToRoute(routeMap.get('dashboards'))) {
    return "dashboards";
  }
  if (hasPermissionToRoute(routeMap.get('devices'))) {
    return "devices";
  }
  if (hasPermissionToRoute(routeMap.get('vpn'))) {
    return "vpn";
  }
  if (hasPermissionToRoute(routeMap.get('devicemap'))) {
    return "devicemap";
  }
  if (hasPermissionToRoute(routeMap.get('data-explore'))) {
    return "data-explore";
  }
  if (hasPermissionToRoute(routeMap.get('alarms'))) {
    return "alarms";
  }
  if (hasPermissionToRoute(routeMap.get('organizations'))) {
    return "organizations";
  }
  if (hasPermissionToRoute(routeMap.get('permission'))) {
    return "permission";
  }
  if (hasPermissionToRoute(routeMap.get('roles'))) {
    return "roles";
  }
  if (hasPermissionToRoute(routeMap.get('dealer'))) {
    return "dealer";
  }
  if (hasPermissionToRoute(routeMap.get('store'))) {
    return "store";
  }
  if (hasPermissionToRoute(routeMap.get('walkthrough'))) {
    return "walkthrough";
  }
  if (hasPermissionToRoute(routeMap.get('notifications'))) {
    return "notifications";
  }


  // If no default route with permission was found go to directaccess
  return "directaccess";
}

async function isAthenticated()
{
  let authenticated = (vuejsKeycloak.state as any).security.auth.authenticated;
  if ( !authenticated )
  {
    await vuejsKeycloakSecurity.init();
    return (vuejsKeycloak.state as any).security.auth.authenticated;
  }
  return true;
}

function loadFontUtil()
{
  // Fix issue with ploty.js and fonts linked in head with //fonts.googleapis.com/css?family=Roboto:300,400,500,700,400italic|Material+Icons"
  const WebFont = require('webfontloader');
  WebFont.load({
    google: {
      //families: ['Roboto:300,400,500,700,400italic|Material+Icons']
      families: [
        'Roboto:300,400,500,700,400italic',
        'Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0,-50..200'
      ]
    }
  });
}

let globalLoader = true;
function removeGlobalLoader()
{
  if ( globalLoader )
  {
    const spinner = document.getElementById("app-loader");
    spinner && spinner.parentNode.removeChild(spinner);
    globalLoader = false;
  }
}