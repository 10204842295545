import { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import { PaginationRestDTO } from '@/interfaces/commons/pagination';
import { UserInDTO, UserOutDTO, UserUpdateDTO, UserQueryParamsDTO, SecretOutDto, UserPreferenceInDTO, ApiKeyInDTO, UserDeepSearchQueryParamsDTO, DeepSearchUserDTO } from '@/interfaces/user';
import { UserAuthorizationDTO,UserGroupInDTO, UserGroupMembershipRole, UserGroupOutDTO, UserGroupQueryParamsDTO, UserGroupUpdateDTO } from '@/interfaces/userGroup';
import { RoleInDTO, RoleOutDTO, RoleUpdateDTO, RoleQueryParamsDTO, DetailedRoleInDTO } from '@/interfaces/role';
import { ApplicationPermissionInDTO } from '@/interfaces/applicationpermission';
import { DeviceController } from '@/interfaces/devicecontroller';
import CommunicationSettings from '../../CommunicationSettings';
import ICorvinaCoreAxiosInstance from '../ICorvinaCoreAxiosInstance';
import AbstractAxiosInstance from './AbstractAxiosInstance';
import { ModelPathQueryParamsDTO, ModelPathInDTO, ModelPathOutDTO } from '@/interfaces/modelpath';
import { OrganizationInDTO, OrganizationOutDTO, OrganizationQueryParamsDTO, OrganizationUpdateDTO, OrganizationLoginInfoDTO, OrganizationGrantDTO, VerifyTokenOutDTO } from '@/interfaces/organization';
import { SecurityPolicyQueryParamsDTO, SecurityPolicyInDTO, SecurityPolicyOutDTO } from '@/interfaces/securitypolicy';
import store from '@/store/store';
import { DeviceOutDTO, DeviceQueryParamsDTO, DeviceRepositoryDTO, DeviceUpdateDTO } from '@/interfaces/device';
import { AgreementOutDTO, AgreementDTOList, CheckAgreementOutDTO, AgreementService } from '@/interfaces/agreements';
import { ApplicationsQueryParamsDTO, ApplicationOutDTO, AppOrganizationOutDTO, AppOrganizationsQueryParamsDTO, AppOrganizationCreateBodyDTO } from '@/interfaces/applications';
import { InstanceDTO } from '@/interfaces/instance';
import { RealmOutDTO } from '@/interfaces/realm';
import { AccountDTO, AccountPatchDTO } from '@/interfaces/account';

class CorvinaCoreAxiosInstance extends AbstractAxiosInstance implements ICorvinaCoreAxiosInstance {

  constructor() {
    super();
    this.updateBaseUrl();
    this.setRequestInterceptor(function (config: InternalAxiosRequestConfig) {
      // fallback is needed only for the first request /svc/core/api/v1/organizations/mine
      config.headers['Authorization'] = store.getters['permission/getAccessToken'] ?? ( CommunicationSettings.$keycloak ? CommunicationSettings.$keycloak.token : "");
      config.timeout = 90000; // mainly because of slow keycloak
      return config;
    })
  }
  
  public updateBaseUrl(): void {
    this.setBaseUrl(CommunicationSettings.corvinaCoreApiUrl());
  }

  fetchInstance(): Promise<InstanceDTO> {
    return this.get('/api/v1/instance');
  }

  fetchUsers(organizationId: number|string, params?: UserQueryParamsDTO): Promise<PaginationRestDTO<UserInDTO>> {
    const config = {
      params,
    };

    return this.get(`/api/v1/organizations/${organizationId}/users`, config);
  }

  deepSearchUsers(params?: UserDeepSearchQueryParamsDTO): Promise<PaginationRestDTO<DeepSearchUserDTO>> {
    const config = {
      params
    };

    return this.get(`/api/v1/organizations/users`, config);
  }

  fetchUser(organizationId: number, userId: number): Promise<UserInDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/users/${userId}`);
  }

  checkUserExists(username: string): Promise<boolean>  {
    return this.get(`/api/v1/users/${username}/exists`);
  }

  fetchUserMine(): Promise<UserInDTO> {
    return this.get(`/api/v1/users/mine`);
  }

  fetchUserByUsername(organizationId: number, username: string): Promise<UserInDTO>  {
    return this.get(`/api/v1/organizations/${organizationId}/users/username/${username}`);
  }

  createUser(organizationId: number, data: UserOutDTO): Promise<UserInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/users`, data);
  }

  updateUser(organizationId: number, userId: number, newEmail: string, newPassword: string, passwordChangeInvitation: boolean, groupPoliciesEnabled: boolean): Promise<UserInDTO> {
    let data: UserUpdateDTO = {
      email: newEmail,
      password: newPassword,
      passwordChangeInvitation,
      groupPoliciesEnabled
    }
    return this.put(`/api/v1/organizations/${organizationId}/users/${userId}`, data);
  }

  deleteUser(organizationId: number, userId: number) : Promise<UserInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/users/${userId}`);
  }

  getUserSecurityPolicyGroups(organizationId: number, userId: number): Promise<SecurityPolicyInDTO[]> {
    return this.get(`/api/v1/organizations/${organizationId}/users/${userId}/securityPolicies`);
  }

  addUserSecurityPolicyGroups(organizationId: number, userId: number, securityPolicyIds: number[]): Promise<SecurityPolicyInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/users/${userId}/securityPolicies/${securityPolicyIds.join(',')}`);
  }

  removeUserSecurityPolicyGroups(organizationId: number, userId: number, securityPolicyIds: number[]): Promise<SecurityPolicyInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/users/${userId}/securityPolicies/${securityPolicyIds.join(',')}`);
  }

  fetchUserGroups(organizationId: number, params?: UserGroupQueryParamsDTO): Promise<PaginationRestDTO<UserGroupInDTO>> {
    const config = {
      params,
    };

    return this.get(`/api/v1/organizations/${organizationId}/userGroups`, config);
  }

  fetchUserGroupsMineMembers(organizationId: number): Promise<{ adminsOf: number[], usersOf: number[] }> {
    return this.get(`/api/v1/organizations/${organizationId}/userGroups/mine/members`);
  }

  fetchUserGroup(organizationId: number, userGroupId: number): Promise<UserGroupInDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}`);
  }

  fetchUserGroupMembers(organizationId: number, userGroupId: number): Promise<UserInDTO[]> {
    return this.get(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}/members`);
  }

  createUserGroup(organizationId: number, data: UserGroupOutDTO): Promise<UserGroupInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/userGroups`, data);
  }

  editUserGroup(organizationId: number, userGroupId: number, data: UserGroupUpdateDTO): Promise<UserGroupInDTO> {
    return this.put(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}`, data);
  }

  addUserToGroup(organizationId: number, userGroupId: number, userId: number, role: UserGroupMembershipRole): Promise<UserGroupInDTO> {
    const config = {
      params: { role },
    };
    return this.post(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}/members/${userId}`, undefined, config);
  }

  addRolesToGroup(organizationId: number, userGroupId: number,  roleIds: number): Promise<UserGroupInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}/roles/${roleIds}`);
  }

  removeUserFromGroup(organizationId: number, userGroupId: number, userId: number): Promise<UserGroupInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}/members/${userId}`);
  }

  removeRolesFromGroup(organizationId: number, userGroupId: number, roleIds: number): Promise<UserGroupInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}/roles/${roleIds}`);
  }

  verifyApplicationPermissionChanges(organizationId : number,  { groupsToAdd, rolesToAdd, groupsToRemove, rolesToRemove } : { groupsToAdd: number[], rolesToAdd: number[], groupsToRemove: number[], rolesToRemove: number[]} ) : Promise<string[]> {
    return this.post(`/api/v1/organizations/${organizationId}/roles/testApplicationPermissions`, {
        groupsToAdd, rolesToAdd, groupsToRemove, rolesToRemove,
        revokedOnly: true
    })
  }

  deleteUserGroup(organizationId: number, userGroupId: number): Promise<UserGroupInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/userGroups/${userGroupId}`);
  }

  isAuthorized(deviceGroup: string, deviceId: string, organizationId: number, vpnName: string): Promise<UserAuthorizationDTO> {
    const config = {
      params: {
        deviceGroup,
        deviceId,
        vpnName
      }
    }
    return this.get(`/api/v1/organizations/${organizationId}/roles/isAuthorized`, config);
  }

  areAuthorized(deviceIds: string[], organizationId: number) : Promise<Record<string, UserAuthorizationDTO>> {
    return this.post(`/api/v1/organizations/${organizationId}/roles/areAuthorized`, deviceIds);
  }

  checkRoleExists(organizationId: number, name: string) {
    return this.get(`/api/v1/organizations/${organizationId}/roles/${name}/exists`);
  }

  fetchRoles(organizationId: number, params?: RoleQueryParamsDTO): Promise<PaginationRestDTO<DetailedRoleInDTO>> {
    const config = {
      params: {
        ...params,
        owners: params?.owners?.join(','),
      },
    };

    return this.get(`/api/v1/organizations/${organizationId}/roles`, config);
  }

  fetchRole(organizationId: number, roleId: number): Promise<DetailedRoleInDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/roles/${roleId}`);
  }

  createRole(organizationId: number, data: RoleOutDTO): Promise<RoleInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/roles`, data);
  }

  editRole(organizationId: number, roleId: number, data: RoleUpdateDTO): Promise<RoleInDTO> {
    return this.put(`/api/v1/organizations/${organizationId}/roles/${roleId}`, data);
  }

  deleteRole(organizationId: number, roleId: number): Promise<DetailedRoleInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/roles/${roleId}`);
  }

  fetchAllApplicationPermissions(): Promise<PaginationRestDTO<ApplicationPermissionInDTO>> {
    const config = {
      params: {
        pageSize: 500,
        orderBy: 'name',
        orderDir: 'ASC'
      }
    };

    return this.get(`/api/v1/applicationpermissions/all`, config);
  }

  fetchDeviceController(organizationId: number, deviceId: number): Promise<DeviceController> {
    return this.get(`/api/v1/organizations/${organizationId}/users/${deviceId}`);
  }

  editDevice(organizationId: number, hwId: string, data: DeviceUpdateDTO): Promise<DeviceOutDTO> {
    return this.patch(`/api/v1/organizations/${organizationId}/devices/${hwId}`, data);
  }

  fetchModelPaths(organizationId: number, params?: ModelPathQueryParamsDTO): Promise<PaginationRestDTO<ModelPathInDTO>> {
    const config = {
      params,
    };
    return this.get(`/api/v1/organizations/${organizationId}/modelPaths`, config);
  }

  createModelPath(organizationId: number, data: ModelPathOutDTO): Promise<ModelPathInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/modelPaths`, data);
  }

  createOrganization(data: OrganizationOutDTO): Promise<OrganizationInDTO> {
    return this.post(`/api/v1/organizations`, data);
  }

  createSubOrganization(organizationId: number, data: OrganizationOutDTO, options?: { addDefaultRoles: boolean, waitLicenseManager: boolean }): Promise<OrganizationInDTO> {
    const config = {
      params: {
        addDefaultRoles: !!options?.addDefaultRoles,
        waitLicenseManager: !!options?.waitLicenseManager
      },
    };

    return this.post(`/api/v1/organizations/${organizationId}`, data, config);
  }

  fetchOrganizations(organizationId: number, params?: OrganizationQueryParamsDTO): Promise<PaginationRestDTO<OrganizationInDTO>> {
    const config = {
      params,
    };

    return this.get(`/api/v1/organizations/${organizationId}/organizations`, config);
  }

  updateSubOrganization(organizationId: number, data: OrganizationUpdateDTO): Promise<OrganizationInDTO> {
    return this.put(`/api/v1/organizations/${organizationId}`, data);
  }

  deleteOrganization(organizationId: number) : Promise<OrganizationInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}`);
  }

  fetchOrganization(organizationId: string): Promise<OrganizationInDTO> {
    return this.get(`/api/v1/organizations/${organizationId}`);
  }

  generateImportToken(organizationId: string): Promise<{ token: string }> {
    return this.post(`/api/v1/organizations/${organizationId}/importToken`);
  }

  verifyImportToken(token: string): Promise<VerifyTokenOutDTO> {
    return this.get(`/api/v1/organizations/importToken/${token}`);
  }

  moveDeviceToAnotherOrganization(organizationId: number, hwId: string, organizationImportToken: string): Promise<DeviceOutDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/devices/${hwId}/move`, { organizationImportToken });
  }

  fetchCurrentUserOrganizations(): Promise<OrganizationInDTO[]> {
    return this.get(`/api/v1/organizations/mine`);
  }

  fetchMyIp(): Promise<OrganizationInDTO[]> {
    return this.get(`/api/v1/organizations/myIp`);
  }

  fetchSecurityPolicyGroups(organizationId: number, params?: SecurityPolicyQueryParamsDTO): Promise<PaginationRestDTO<SecurityPolicyInDTO>> {
    const config = {
      params,
    };

    return this.get(`/api/v1/organizations/${organizationId}/securityPolicies`, config);
  }

  createSecurityPolicyGroup(organizationId: number, data: SecurityPolicyOutDTO): Promise<SecurityPolicyInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/securityPolicies`, data);
  }

  fetchSecurityPolicyGroup(organizationId: number, securityPolicyGroupId: number): Promise<SecurityPolicyInDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}`);
  }

  updateSecurityPolicyGroup(organizationId: number, securityPolicyGroupId: number, data: SecurityPolicyOutDTO): Promise<SecurityPolicyInDTO> {
    return this.put(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}`, data);
  }

  deleteSecurityPolicyGroup(organizationId: number, securityPolicyGroupId: number): any {
    return this.delete(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}`);
  }

  addDeviceToSecurityGroup(organizationId: number, securityPolicyGroupId: number, deviceId: number): Promise<SecurityPolicyInDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}/devices/${deviceId}`);
  }

  removeDeviceFromSecurityGroup(organizationId: number, securityPolicyGroupId: number, deviceId: number): Promise<SecurityPolicyInDTO> {
    return this.delete(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}/devices/${deviceId}`);
  }

  fetchSecurityPolicyGroupsByParent(organizationId: number, securityPolicyGroupId: number, params?: SecurityPolicyQueryParamsDTO): Promise<PaginationRestDTO<SecurityPolicyInDTO>> {
    const config = {
      params,
    };

    return this.get(`/api/v1/organizations/${organizationId}/securityPolicies/${securityPolicyGroupId}/securityPolicies`, config);
  }

  getLoginInfo(organization: string) : Promise<OrganizationLoginInfoDTO> {
    const config = {
      params: {
        hostname: organization
      }
    };

    return this.get(`/api/v1/organizations/loginInfo`, config);
  }

  fetchModelPathPermissions(organizationId: number, roleId: string, params?: any): any {
    const config = {
      params
    };

    return this.get(`/api/v1/organizations/${organizationId}/roles/${roleId}/modelPathPermissions`, config);
  }

  fetchDevices(organizationId: number, params?: DeviceQueryParamsDTO): Promise<PaginationRestDTO<DeviceRepositoryDTO>> {
    const config = {
      params
    }

    return this.get(`/api/v1/organizations/${organizationId}/devices`, config);
  }

  searchDevices(organizationId: number, params?: DeviceQueryParamsDTO): Promise<PaginationRestDTO<DeviceRepositoryDTO>> {
    const config = {
      params
    }

    return this.get(`/api/v1/organizations/${organizationId}/devices/search`, config);
  }

  fetchDeviceByLabel(organizationId: number, deviceLabel: string): Promise<DeviceOutDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/devices/label/${deviceLabel}`);
  }

  userSelfOnboarding(userData): Promise<void> {
    const config = {
      timeout: 90000 // give enough time for the service to complete the onboarding
    }
    return this.post(`/api/v1/organizations/selfOnBoarding`, { agreementsServiceName: AgreementService.AGREEMENTS_SERVICE_NAME, ...userData }, config);
  }

  fetchServiceAccountClientSecret(organizationId: number, clientId: string) : Promise<SecretOutDto> {
    return this.get(`/api/v1/organizations/${organizationId}/clients/${clientId}/secret`, {});
  }

  refreshServiceAccountClientSecret(organizationId: number, clientId: string) : Promise<SecretOutDto> {
    return this.post(`/api/v1/organizations/${organizationId}/clients/${clientId}/secret`, {});
  }

  

  /**********************************************************************
   *  AGREEMENTS 
   **********************************************************************/
  fetchAgreements(organizationId: number, serviceName:AgreementService) : Promise<AgreementOutDTO[]> {
    const config = {
      params: {
        serviceName
      }
    }
    return this.get(`/api/v1/organizations/${organizationId}/agreements/last`, config)
  }

  checkAgreements(organizationId: number, serviceName:AgreementService) : Promise<CheckAgreementOutDTO> {
    const config = {
      params: {
        serviceName
      }
    }
    return this.get(`/api/v1/organizations/${organizationId}/agreements/check`, config)
  }

  acceptAgreements(organizationId: number, idsToAccept: number[]) : Promise<AgreementOutDTO[]> {
    const data : AgreementDTOList = {
      ids: idsToAccept
    }
    return this.post(`/api/v1/organizations/${organizationId}/agreements/accept`, data);
  }

  async getUserPreferences(params):Promise<UserPreferenceInDTO>{
    const orgResourceId = store.getters['permission/getOrganizations'].selectedSubOrganization.resourceId
    const config = {
      params
    }
    return this.get(`/api/v1/organizations/${orgResourceId}/userPreferences`, config)
  }

  async postUserPreferences(params){
    const orgResourceId = store.getters['permission/getOrganizations'].selectedSubOrganization.resourceId
    return this.post(`/api/v1/organizations/${orgResourceId}/userPreferences`, params)
  }

  async deleteUserPreferences(userPrefId:number){
    const orgResourceId = store.getters['permission/getOrganizations'].selectedSubOrganization.resourceId
    return this.delete(`/api/v1/organizations/${orgResourceId}/userPreferences/${userPrefId}`)
  }

  async getUserApiKeys(username?: string):Promise<ApiKeyInDTO>{
    const orgId = store.getters['permission/getOrganizations'].selectedSubOrganization.id
    const config = {
      params: {
        username
      }
    }
    return this.get(`/api/v1/organizations/${orgId}/apiKeys`, config)
  }

  async postUserApiKey(data: { username?: string } ){
    const orgId = store.getters['permission/getOrganizations'].selectedSubOrganization.id
    return this.post(`/api/v1/organizations/${orgId}/apiKeys`, data)
  }

  async deleteUserApiKey(apiKeyId:number, username: string){
    const orgId = store.getters['permission/getOrganizations'].selectedSubOrganization.id
    const config = {
      params: {
        username
      }
    }
    return this.delete(`/api/v1/organizations/${orgId}/apiKeys/${apiKeyId}`, config)
  }

  async fetchApplications(params?: ApplicationsQueryParamsDTO): Promise<PaginationRestDTO<ApplicationOutDTO>> {
    const config = {
      params
    }

    return this.get(`/api/v1/apps`, config);
  }

  async getApplication(appId: number): Promise<ApplicationOutDTO> {
    return this.get(`/api/v1/apps/${appId}`);
  }

  async fetchApplicationLike(appId: number) : Promise<{ appId: number, username: string } | undefined> {
    return this.get(`/api/v1/apps/${appId}/like`);
  }

  async createApplicationLike(appId: number) : Promise<{ appId: number, username: string } | undefined> {
    return this.post(`/api/v1/apps/${appId}/like`);
  }

  async deleteApplicationLike(appId: number) : Promise<{ appId: number, username: string } | undefined> {
    return this.delete(`/api/v1/apps/${appId}/like`);
  }

  async fetchAppOrganizations(organizationId: number, params?: AppOrganizationsQueryParamsDTO): Promise<PaginationRestDTO<AppOrganizationOutDTO>> {
    const config = {
      params
    }

    return this.get(`/api/v1/organizations/${organizationId}/apps`, config);
  }

  async getAppOrganization(organizationId: number, appOrganizationId: number): Promise<AppOrganizationOutDTO> {
    return this.get(`/api/v1/organizations/${organizationId}/apps/${appOrganizationId}`);
  }

  async createAppOrganization(organizationId: number, appOrganization: AppOrganizationCreateBodyDTO): Promise<AppOrganizationOutDTO> {
    return this.post(`/api/v1/organizations/${organizationId}/apps`, appOrganization);
  }

  async upgradeAppOrganization(organizationId: number, appOrganizationId: number, planId?: string): Promise<AppOrganizationOutDTO> {
    const url = new URL(`/api/v1/organizations/${organizationId}/apps/${appOrganizationId}`, CommunicationSettings.corvinaCoreApiUrl());

    if (planId) {
      url.searchParams.append('planId', planId);
    }

    return this.put(url.pathname + url.search + url.hash);
  }

  async deleteAppOrganization(organizationId: number, appOrganizationId: number, force?: boolean): Promise<AppOrganizationOutDTO> {
    let url = `/api/v1/organizations/${organizationId}/apps/${appOrganizationId}`;

    if (force === true) {
      url += '?force=true';
    }

    return this.delete(url);
  }

  async fetchUserRealms() : Promise<RealmOutDTO[]> {
    return this.get(`/api/v1/organizations/mineRealms`);
  }
  
  async fetchOrganizationGrants(parentOrg: string) : Promise<OrganizationGrantDTO> {
    return this.get(`/api/v1/organizations/${parentOrg}/grants`);
  }

  async fetchAccount(): Promise<AccountDTO> {
    return this.get(`/api/v1/account`, {});
  }

  async patchAccount(patch: AccountPatchDTO): Promise<void> {
    return this.patch(`/api/v1/account`, patch);
  }

  async removeAccountCredential(id: string): Promise<void> {
    return this.delete(`/api/v1/account/credentials/${id}`);
  }

  async removeAccountSession(id: string): Promise<void> {
    return this.delete(`/api/v1/account/sessions/${id}`);
  }


}

export default new CorvinaCoreAxiosInstance();
